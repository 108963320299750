import { InputStyles } from '@ui/Input';
import CurrencyInput from '@ui/Input/masked/CurrencyInput';
import { getNumber } from '@util/createListingHelpers';
import { cx } from 'class-variance-authority';
import { ReactNode } from 'react';

interface InputProps {
  error?: boolean;
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  value?: string | number;
  placeholder?: string;
  disabled?: boolean;
  full?: boolean;
  allowEmpty?: boolean;
  className?: string;
}

const FormCurrencyInputFancy = (props: InputProps & { before?: ReactNode }) => {
  const {
    error,
    defaultValue,
    before,
    placeholder,
    disabled,
    full,
    onChange,
    onClick,
    className,
    ...inputProps
  } = props;
  const beforeEl = before ? (
    before
  ) : (
    <div className="min-w-[3rem] text-center text-inherit">$</div>
  );
  return (
    <div
      className={cx([
        InputStyles({
          type: 'noPadding',
          onDark: false,
          error,
          focus: 'default',
          disabled,
          full,
        }),
        'max-w-[20rem] overflow-clip !rounded-2xl',
        disabled ? 'bg-brand-gray-light select-none text-brand-gray' : '',
        className,
      ])}
    >
      <div
        className={cx([
          'flex w-fit  select-none flex-col items-center justify-center self-stretch bg-brand-secondary-light px-4 font-semibold',
          disabled ? 'select-none text-brand-gray' : 'text-brand-secondary',
        ])}
      >
        {beforeEl}
      </div>
      <div className="w-full py-[1rem]">
        <CurrencyInput
          maskOptions={{
            decimalLimit: 2,
            integerLimit: 7,
            decimalSymbol: '.',
            allowDecimal: true,
          }}
          disabled={disabled}
          defaultValue={getNumber(defaultValue) >= 0 ? defaultValue : ''}
          placeholder={placeholder}
          onChange={(e) => {
            const newEvent = {
              ...e,
              target: {
                ...e.target,
                value:
                  e.target.value !== ''
                    ? getNumber(e.target.value)
                    : props.allowEmpty
                    ? ''
                    : 0,
              },
            };
            // @ts-ignore - this is a hack i just want this to emit a number not a string
            onChange?.(newEvent);
          }}
          onClick={onClick}
          {...inputProps}
        />
      </div>
    </div>
  );
};

export default FormCurrencyInputFancy;
