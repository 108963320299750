import { CloseIcon } from '@c/icons';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import useNavigationEvent from '@util/hooks/useNavigationEvent';
import { cva } from 'class-variance-authority';
import { Fragment, Suspense, useCallback } from 'react';

const styles = cva(
  'text-[1.5rem] flex flex-col relative  text-left text-brand-black shadow-xl transition-all max-h-screen',
  {
    variants: {
      isFullScreen: {
        true: 'w-full h-full',
        false:
          'w-fit xl:max-w-[90%] min-h-[32rem] min-w-[32rem] p-[2.4rem] rounded-2xl mx-4',
      },
      overflowType: {
        auto: 'overflow-auto',
        visible: 'overflow-y-visible',
        hidden: 'overflow-hidden',
      },
      bgTransparent: {
        true: 'bg-black/70',
        false: 'bg-white',
      },
      noPadding: {
        true: '!p-0',
        false: '',
      },
      modalRounded: {
        true: 'rounded-[3rem]',
        false: '',
      },
    },
  }
);
export interface BaseModalProps {
  isOpen: boolean;
  dismiss?: () => void;
  children?: React.ReactNode;
  title?: React.ReactNode;
  hideCloseIcon?: boolean;
  isFullScreen?: boolean;
  disableBackdropDismiss?: boolean;
  noPadding?: boolean;
  overflowType?: 'auto' | 'visible' | 'hidden';
  bgTransparent?: boolean;
  className?: string;
  modalRounded?: boolean;
}

function BaseModal({
  children,
  title,
  hideCloseIcon,
  isOpen,
  dismiss,
  isFullScreen = false,
  disableBackdropDismiss = false,
  overflowType = 'auto',
  bgTransparent = false,
  noPadding = false,
  className,
  modalRounded = false,
}: BaseModalProps) {
  const showTitleSection = !!title || !hideCloseIcon;

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-[70] ${className}`}
        onClose={() => dismiss?.()}
        static={disableBackdropDismiss}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {disableBackdropDismiss ? (
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          ) : (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          )}
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex h-full items-center justify-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={styles({
                  isFullScreen,
                  overflowType,
                  bgTransparent,
                  noPadding,
                  modalRounded,
                })}
              >
                {showTitleSection && (
                  <div
                    className={`mb-[2.4rem] mt-[1.6rem] flex items-center sm:mt-0 ${
                      !!title ? 'justify-between' : 'justify-end'
                    }`}
                  >
                    {!!title && <DialogTitle>{title}</DialogTitle>}
                    {!hideCloseIcon && (
                      <Suspense>
                        <BaseModalCloseButtoon
                          dismiss={dismiss}
                          bgTransparent={bgTransparent}
                        />
                      </Suspense>
                    )}
                  </div>
                )}
                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

import React from 'react';

const BaseModalCloseButtoon = ({
  dismiss,
  bgTransparent,
}: {
  dismiss?: () => void;
  bgTransparent?: boolean;
}) => {
  useNavigationEvent(
    useCallback(({ url, previousUrl }) => {
      if (!url || !previousUrl) return;
      if (url !== previousUrl) {
        dismiss?.();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );
  return (
    <button
      className={`m-4 ${bgTransparent ? 'text-white' : 'text-black'}`}
      type="button"
      onClick={() => dismiss?.()}
    >
      <CloseIcon />
    </button>
  );
};

export default BaseModal;
